<style scoped lang="less">
  .avatar {
    width: 144px;
    height: 144px;
    border-radius: 72px;
    object-fit: cover;
    margin-right: 40px;
  }
  .details-info {
    display: flex;
    align-items: center;
  }
  .right {
    flex: 1;
    line-height: 2;
    table, td {
      padding: 0;
      border-collapse: collapse;
      border-spacing: 0;
    }
    .item {
      .iconfont {
        margin-right: 10px;
      }
      & + .item {
        margin-left: 70px;
      }
    }
  }

  .switch-btn {
    line-height: 2;
    margin-right: 20px;
    border-radius: 20px;
    padding: 0 10px;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
  }
  
  .blue-theme, .dark-theme {
    .switch-btn {
      color: #6F76B7;
      border: 1px solid #6F76B7;
      background-color: #F0F1FA;
    }
  }
  .pink-theme {
    .switch-btn {
      color: #F4628F;
      border: 1px solid #F4628F;
      background-color: rgba(244, 98, 143, .1);
    }
  }
  .yellow-theme {
    .switch-btn {
      color: #6C573C;
      border: 1px solid #6C573C;
      background-color: rgba(108, 87, 60, .1);
    }
  }
</style>

<template>
  <div class="details-info">
    <img :src="src" class="avatar" />
    <div class="right">
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <div style="display: flex;">
          <div class="item text-value">{{data.name || '-'}}</div>
          <div class="item">
            <i class="iconfont icon-yonghu1 theme-text-color"></i>
            <span class="text-field">职务：</span>
            <span class="text-value">{{positionText || '-'}}</span>
          </div>
          <div class="item">
            <i class="iconfont icon-gongshi theme-text-color"></i>
            <span class="text-field">工龄：</span>
            <span class="text-value">{{data.maritalStatus || '-'}}</span>
          </div>
        </div>
        <router-link tag="span" :to="{
          name: 'resume.preview',
          query: { id: id }
        }" class="switch-btn"><i class="iconfont icon-switch"></i>扫描件模式</router-link>
      </div>
      <table style="width: 80%">
        <tr>
          <td>
            <span class="text-field">所属机构：</span>
            <span class="text-value">{{orgText || '-'}}</span>
          </td>
          <td>
            <span class="text-field">是否在编：</span>
            <span class="text-value">{{data.type || '-'}}</span>
          </td>
          <td>
            <span class="text-field">在岗状态：</span>
            <span class="text-value">{{data.jobTypeC || '-'}}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="text-field">参加工作时间：</span>
            <span class="text-value">{{(data.correctionDate || '-').substr(0, 10)}}</span>
          </td>
          <td>
            <span class="text-field">进入本单位时间：</span>
            <span class="text-value">{{(data.entryDate || '-').substr(0, 10)}}</span>
          </td>
          <td>
            <span class="text-field">人员类别：</span>
            <span class="text-value">{{data.jobType || '-'}}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { getWorkerAvatar, getWorkerInfo } from '@/views/resume/libs'

export default {
  data () {
    return {
      src: null,
      data: {}
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    orgList () {
      return this.$store.getters.orgList
    },
    orgText () {
      const org = this.$store.getters.orgList.find(v => v.key === this.data.orgid)
      return org ? org.label : null
    },
    positionList () {
      return this.$store.getters.positionList
    },
    positionMap () {
      let data = {}
      this.positionList.forEach(v => data[v.key] = v.label)
      return data
    },
    positionText () {
      return this.positionMap[this.data.positionId] || null
    }
  },
  watch: {
    id () {
      // this.loadAvatar()
      this.loadInfo()
    }
  },
  methods: {
    async loadAvatar () {
      this.src = this.data && this.data.headFileId ? await getWorkerAvatar(this.data.headFileId) : null
    },
    async loadInfo () {
      this.data = await getWorkerInfo(this.id)
      this.loadAvatar()
    }
  },
  mounted () {
    this.loadAvatar()
    this.loadInfo()
  }
}
</script>
