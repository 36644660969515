<style scoped lang="less">
  .header {
    background-color: #FFF;
    padding: 18px 18px 0 18px;
    box-sizing: border-box;
  }

  .content {
    padding: 20px 20px 0 20px;
    flex: 1;
    overflow: auto;
  }
</style>

<style lang="less">
  .text-field {
    color: rgba(0, 0, 0, .7);
    font-size: 16px;
  }

  .text-value {
    color: #000;
    font-size: 16px;
  }
</style>

<style lang="less">
  .resume-details-page {
    .details-search {
      .back, .input, .search-btn, .fm-input-new-append {
        transition: all .3s;
      }
    }
    .pack-up-margin {
      transition: all .3s;
      margin-top: 30px;
    }
    .details-info {
      .avatar, .text-field, .text-value {
        transition: all .3s;
      }
    }
  }
  .resume-pack-up {
    .details-search {
      .back { font-size: 14px; }
      .input {
        margin-top: 0;
      }
      .fm-input-new {
        height: 30px;
      }
      .fm-input-new-append {
        height: 30px !important;
        line-height: 30px !important;
      }
      .search-btn {
        height: 30px;
        line-height: 30px;
      }
    }
    .pack-up-margin {
      margin-top: 10px;
    }
    .details-info {
      .avatar {
        width: 70px;
        height: 70px;
      }
      .right {
        line-height: 1.5 !important;
        .text-field, .text-value {
          font-size: 14px !important;
        }
      }
    }
    .details-menus {
      .menu span {
        font-size: 14px;
      }
    }
  }
</style>

<template>
  <div style="display: flex;flex-direction: column;" class="resume-details-page">
    <div class="header" :class="{'resume-pack-up': packUp}">
      <search />
      <info class="pack-up-margin" />
      <menus class="pack-up-margin" />
    </div>
    <div class="content" ref="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Search from '@/views/resume/components/details/search'
import Info from '@/views/resume/components/details/info'
import Menus from '@/views/resume/components/details/menus'

import { getWorkerInfo } from '@/views/resume/libs'

let timer = null

export default {
  components: { Search, Info, Menus },
  data () {
    return {
      packUp: false
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  watch: {
    id: {
      immediate: true,
      handler (v) {
        if (!v && this.$route.name.indexOf('resume.details') === 0) {
          this.$Message.error('参数缺失')
          this.$router.back()
        } else {
          this.loadData()
        }
      }
    }
  },
  methods: {
    async loadData () {
      await getWorkerInfo(this.id)
    },
    onMousewheel (e) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (e.deltaY > 0) {
          this.packUp = true
        } else if (e.deltaY < 0) {
          this.packUp = false
        }
      }, 0)
    }
  },
  mounted () {
    this.loadData()
    this.$store.dispatch('loadPositionList')
    this.$store.dispatch('loadOrgList')
    this.$refs.content.addEventListener('mousewheel', this.onMousewheel)
  },
  destroyed () {
    this.$refs.content && this.$refs.content.removeEventListener('mousewheel', this.onMousewheel)
  }
}
</script>
